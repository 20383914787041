import React from 'react';

const HealthCheck = () => {
    const healthStatus = {
        status: 'Frontend OK',
        timestamp: new Date().toISOString(),
        environment: process.env.NODE_ENV || 'development',
        version: process.env.REACT_APP_VERSION || '1.0.0',
    };

    return (
        <div>
            <h1>Frontend Health Check</h1>
            <p>Status: {healthStatus.status}</p>
            <p>Timestamp: {new Date(healthStatus.timestamp).toLocaleString()}</p>
            <p>Environment: {healthStatus.environment}</p>
            <p>Version: {healthStatus.version}</p>
        </div>
    );
};

export default HealthCheck;
