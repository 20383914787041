import './App.css';
import Product from './Product';
import HealthCheck from './HealthCheck';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">

      <div className="header">
        <img src="/logo.png" />
        <h1>Navigate Product Features</h1>
      </div>

      <Router>

        <Routes>
          <Route exact path="HealthCheck" element={<HealthCheck />} />
          <Route exact path="/" element={<Product />} />
        </Routes>
      </Router>



    </div>
  );
}



export default App;
