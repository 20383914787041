import React, { useEffect, useState  } from 'react';
import axios from 'axios';
import './Product.css';



const environments = {
    vdev: {
        apiUrl: process.env.REACT_APP_vdev_API_URL,
        apiKey: process.env.REACT_APP_vdev_API_KEY
    },
    vdemo: {
        apiUrl: process.env.REACT_APP_vdemo_API_URL,
        apiKey: process.env.REACT_APP_vdemo_API_KEY
    },
    vstag: {
        apiUrl: process.env.REACT_APP_vstag_API_URL,
        apiKey: process.env.REACT_APP_vstag_API_KEY
    },
    vsbox: {
        apiUrl: process.env.REACT_APP_vsbox_API_URL,
        apiKey: process.env.REACT_APP_vsbox_API_KEY
    },
    vprod: {
        apiUrl: process.env.REACT_APP_vprod_API_URL,
        apiKey: process.env.REACT_APP_vprod_API_KEY
    },
    etisalatstag: {
        apiUrl: process.env.REACT_APP_etisalatstag_API_URL,
        apiKey: process.env.REACT_APP_etisalatstag_API_KEY
    },
    etisalatprod: {
        apiUrl: process.env.REACT_APP_etisalatprod_API_URL,
        apiKey: process.env.REACT_APP_etisalatprod_API_KEY
    }
};

const Product = () => {
    const [products, setProducts] = useState([]);

    const [selectedEnv, setSelectedEnv] = useState('vdev');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchProducts = () => {
        setLoading(true);
        setError('');
        const { apiUrl, apiKey } = environments[selectedEnv];
        axios.get(apiUrl, {
            headers: {
                'accept': 'application/json',
                'X-API-KEY': apiKey,
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => {
                setProducts(response.data);
                if (response.data.length === 0) {
                    setError('No products found.');
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('There was an error fetching the product data!', error);
                setError('Error fetching products. Please try again later.');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProducts();
    }, [selectedEnv]);

    if (loading) {
        return <div className='msgloading'>Loading...</div>;
    }

    if (!products.length) {
        return <div>No products available.</div>;
    }

    return (
        <div className="product-container">
            <div class="env-select">
                <label htmlFor="env-select">Select Environment: </label>
                <select
                    id="env-select"
                    value={selectedEnv}
                    onChange={e => setSelectedEnv(e.target.value)}
                >
                    <option value="vdev">Vantiva Development</option>
                    <option value="vdemo">Vantiva Demo</option>
                    <option value="vstag">Vantiva Staging</option>
                    <option value="vsbox">Vantiva Sandbox</option>
                    <option value="vprod">Vantiva Production</option>
                    <option value="etisalatstag">Etisalat Staging</option>
                    <option value="etisalatprod">Etisalat Production</option>


                </select>
            </div>
            {loading && <div>Loading...</div>}
            {error && <div className="error-message">{error}</div>}
            {!loading && !error && products.length > 0 && (
            <table className="product-table">
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Speed Test Enabled</th>
                        <th>Telephony Enabled</th>
                        <th>Extender or Mesh Enabled</th>
                        <th>Device Map Enabled</th>
                        <th>Launch 5G Enabled</th>
                        <th>Split Merge Enabled</th>
                        <th>Show Support Button</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product.id}>
                            <td>{product.model}</td>
                            <td>{product.type}</td>
                            <td className={product.speedTestEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.speedTestEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.telephonyEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.telephonyEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.extenderOrMeshEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.extenderOrMeshEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.deviceMapEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.deviceMapEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.launch5gEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.launch5gEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.splitMergeEnabled === 'true' ? 'check-symbol' : 'cross-symbol'}>
                                {product.splitMergeEnabled === 'true' ? '✔️' : '❌'}
                            </td>
                            <td className={product.properties.showSupportButton ? 'check-symbol' : 'cross-symbol'}>
                                {product.properties.showSupportButton ? '✔️' : '❌'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
             )}
        </div>
    );
};

export default Product;
